<template>
  <BaseNavItem
    :title="$t('labels.firms')"
    :icon="$vuetify.icons.values.firms"
    :route="targetRoute"
    :visible="visible"
  />
</template>

<script>
import { mapState } from 'vuex'
import { BaseNavItem } from '@argon/app/components'
import firmPerimeter from '../perimeters'

export default {
  components: { BaseNavItem },
  perimeters: [firmPerimeter],
  computed: {
    targetRoute() {
      return {
        name: 'firms',
        params: this.$route.params
      }
    },
    visible() {
      return this.$firm.isAllowed('atLeastAdvisor')
    }
  }
}
</script>
